<template>
  <div class="main_container">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/analyses/board'">
          Analyses >
        </router-link>
        <!-- <router-link :to="'/analyses/bplan'">
          
        </router-link> -->
        <span>Business plan</span>
      </div>
    </div>
    
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Business plan
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <a class="btn btn-blue"
           data-toggle="modal"
           ref="modal_button"
           data-target="#exampleModal"
           @click="effacerForm()">
          Créer un projet
        </a>
      </div>
      
    </div>
    <div class="row pt-3 mx-auto">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th-blue"
                width="20%"> 
              Libellé
            </th>

            <th class="th-blue"
                width="20%">
              Date 
            </th>
 
            <th class="th-blue"
                width="20%">
              Commentaire
            </th>
            <th class="th-blue text-center"
                width="20%">
              Actions
            </th>
          </tr>
        </thead>
        <tbody v-if="!loaded">
          <tr scope="row">
            <td colspan="5"
                class="text-center">Chargement en cours... </td>
          </tr>
        </tbody>
        <tbody v-else-if="loaded && long ==0">
          <tr scope="row">
            <td colspan="5"
                class="text-center">Aucune donnée enregistrée...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr scope="row"
              v-for="(projet, prokey) in projets"
              :key="prokey">
            <td >{{ projet.libelle }}</td>
            <td >{{ projet.created_at }}</td>
            <td>{{ projet.commentaire }}</td>
            <td class="text-right">
              <button class="btn btn-blue mr-1"
                      @click="launchListProduits(projet.id)">+</button>
              <button type="button"
                      data-toggle="modal" 
                      data-target="#exampleModal" 
                      class="btn btn-blue mr-1"
                      @click="chargerProjet(projet.id)">
                <i class="flaticon-pencil"></i>
              </button>
              <button type="button"
                      class="btn btn-blue"
                      @click="chargerRecap(projet.id)">
                <i class="flaticon-files"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel">Créer un projet</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="btn-close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for=""
                >Libellé <sup><span class="req-star">*</span></sup></label>
                <input type="text"
                       class="form-control"
                       v-model="form.libelle">
              </div>
              <div class="form-group">
                <label for="">Commentaire</label>
                <textarea class="form-control"
                          rows="3"
                          v-model="form.commentaire"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    @click="effacerForm()">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    v-if="!modification"
                    @click="submit">créer</button>
            <button type="button"
                    class="btn btn-primary"
                    v-else
                    @click="modifierProjet()">Modifier</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .table tbody td,
  .table tfoot td,
  .table thead th,
  .form input.form-control,
  .form button.btn-info{
    font-size: 0.54em;
  }
  .table tbody tr{
    cursor: pointer;
  }

</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
export default {
  name: "AddBplan",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    projets:[],
    loaded:false,
    long:0,
    modification:false,
    form: form({
      id:"",
      libelle:"",
      commentaire:"",
    }).rules({
      libelle:"required",
    }),
  }),
  created() {
    this.getListProjets()
  },
  watch: {
    listProjets(){
      if (this.listProjets) {
        this.projets=this.listProjets.donnees
        this.projets.forEach(projet => {
          console.log(projet)
          projet.created_at=projet.created_at.split("T")[0]
        })
        this.long= this.projets.length
        this.loaded=true
      }
    },
    failListProjets(){
      if (this.failListProjets) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
    projet(){
      if (this.projet) {
        console.log(this.projet)
        this.form.id=this.projet.id
        this.form.libelle=this.projet.libelle
        this.form.commentaire=this.projet.commentaire
      }
    },
    failProjet(){
      if (this.failProjet) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
    addProjet(){
      if (this.addProjet) {
        this.notif.message = "Le projet a été créer."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs["btn-close"].click()
            this.effacerForm()
            this.loaded=false
            this.getListProjets()
          }.bind(this),
          2000
        )
      }
    },
    msgSuccessProjet(){
      if (this.msgSuccessProjet) {
        this.notif.message = "Le projet a été modifié."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs["btn-close"].click()
            this.effacerForm()
            this.loaded=false
            this.getListProjets()
          }.bind(this),
          2000
        )
      }
    },
    failAddProjet(){
      if (this.failAddProjet) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    },
    msgFailProjet(){
      if (this.msgFailProjet) {
        this.notif.message = "Une erreur s'est produite."
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          2000
        )
      }
    }
  },
  computed: {
    ...mapGetters(["listProjets","addProjet","projet","msgSuccessProjet","failListProjets","failAddProjet","failProjet","msgFailProjet"])
  },
  methods: {
    ...mapActions(["getListProjets","postProjet","getProjet","putProjet"]),
    ...mapMutations(["setListProjets","setAddProjet","setProjet","setMsgSuccessProjet","setFailListProjets","setFailAddProjet","setFailProjet","setMsgFailProjet"]),
    launchListProduits(id) {
      this.$router.push({ name: "Produits", params: { id: id } })
    },
    chargerRecap(id) {
      this.$router.push({ name: "RecapBusiness", params: { id: id } })
    },
    effacerForm(){
      this.form.id=""
      this.form.libelle=""
      this.form.commentaire=""
    },
    submit(){
      this.postProjet(this.form.data)
    },
    modifierProjet(){
      if (this.form.commentaire==null) {
        this.form.commentaire=""
      }
      this.putProjet(this.form.data)
    },
    chargerProjet(id){
      this.modification=true
      this.getProjet(id)
    }
  }
}
</script>
